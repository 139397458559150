import React from 'react';

function RightSidebar() {
  return (
    <div className="sidebar right-sidebar">
      
    </div>
  );
}

export default RightSidebar;
